<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-secondary text-center">
          Thank you for your registering. Please check your email to complete the registration
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingupPersonalCheckEmail',
};
</script>

<style scoped></style>
